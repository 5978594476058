import api from '@/api'
import omitBy from 'lodash/omitBy'

const state = {
    list: undefined,
    selected: undefined,
}

const mutations = {
    SET_VENUES(state, data) {
        state.list = data
    },
    SET_SELECTED_VENUE(state, venue) {
        state.selected = venue
    },
}

const getters = {
    show_venues(state, getters) {
        return getters.is_subscribed && state.list && state.list.length >= 1
    },
    selected_venue(state) {
        return state.selected
    },
}

const actions = {
    getVenuesList({ commit, dispatch }) {
        return api
            .get('/workspaces/venues')
            .then(({ data }) => {
                commit('SET_VENUES', data)
                return data
            })
            .catch(e => dispatch('handleError', e))
    },
    getVenues({ dispatch }, { pagination, search, filters, facilities }) {
        if (pagination) delete pagination.totalItems

        return api
            .get('/venues', { params: { ...pagination, search, filters, facilities } })
            .catch(e => dispatch('handleError', e))
    },
    getVenue({ dispatch }, id) {
        return api
            .get(`/venues/${id}`)
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
    getVenueActivity({ dispatch }, { id, pagination, filters = {} }) {
        if (!id) {
            return Promise.resolve()
        }
        pagination ? delete pagination.totalItems : false
        const definedFilters = omitBy(filters, v => [null, undefined].includes(v))

        return api
            .get(`/venues/${id}/activity`, {
                params: { ...definedFilters, ...pagination },
            })
            .then(response => response)
            .catch(e => dispatch('handleError', e))
    },
    exportVenueActivity({ dispatch }, { id, filters }) {
        if (!id) {
            return Promise.resolve()
        }
        const definedFilters = omitBy(filters, v => [null, undefined].includes(v))

        return api
            .post(`/venues/${id}/activity/export`, { ...definedFilters })
            .catch(e => dispatch('handleError', e))
    },
    exportVenueLoungePasses({ dispatch }, { id, date }) {
        return api
            .post(`/venues/${id}/lounge/export`, { date })
            .catch(e => dispatch('handleError', e))
    },
    createVenue({ dispatch }, venue) {
        return api
            .post('/venues', { venue })
            .then(response => {
                dispatch('getVenuesList')
                return response.data
            })
            .catch(e => dispatch('handleError', e))
    },
    updateVenue({ dispatch }, venue) {
        return api
            .patch(`/venues/${venue.id}`, { venue })
            .then(response => {
                dispatch('getVenuesList')
                return response.data
            })
            .catch(e => dispatch('handleError', e))
    },
    deleteVenue({ dispatch }, id) {
        return api.delete(`/venues/${id}`, {}).catch(e => dispatch('handleError', e))
    },
    getVenueContacts({ dispatch }, { id, pagination }) {
        return api
            .get(`/venues/${id}/contacts`, { params: { ...pagination } })
            .catch(e => dispatch('handleError', e))
    },
    getSquareLocations({ dispatch }) {
        return api
            .get(`/square/locations`)
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
    importVenuesFromCSV({ dispatch }, params = {}) {
        const { file, facilityId } = params
        const formData = new FormData()

        let url = `/venues/import`

        formData.append('file', file)

        if (facilityId) url += `/${facilityId}`

        return api
            .request({
                url,
                method: 'post',
                headers: { 'Content-Type': 'multipart/form-data' },
                data: formData,
            })
            .catch(e => dispatch('handleError', e))
    },
    getTenants({ dispatch }, params = {}) {
        let { pagination, search, shop_category, facilityId } = params
        pagination ? delete pagination.totalItems : false
        return api
            .get(`/venues/${facilityId}/tenants`, {
                params: { ...pagination, search, shop_category },
            })
            .catch(e => dispatch('handleError', e))
    },
    getMonetaryStats({ dispatch }, params = {}) {
        let { pagination, filters, id } = params
        pagination ? delete pagination.totalItems : false
        return api
            .get(`/venues/${id}/monetary`, { params: { ...pagination, filters } })
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
    exportMonetaryStats({ dispatch }, { id, ...params }) {
        return api
            .post(`/venues/${id}/monetary/export`, params)
            .then(({ location }) => location)
            .catch(e => dispatch('handleError', e))
    },
    selectVenue({ commit }, venue) {
        commit('SET_SELECTED_VENUE', venue)
    },
    clearSelectedVenue({ commit }) {
        commit('SET_SELECTED_VENUE', undefined)
    },
    clearVenuesList({ commit }) {
        commit('SET_VENUES', undefined)
    },
    printVenues({ dispatch }, id) {
        return api
            .post('/venues/print/' + (id ? id : ''), {})
            .then(({ location }) => location) // location - path to the created file.
            .catch(e => dispatch('handleError', e))
    },
    exportVenues({ dispatch }, id) {
        return api
            .post('/venues/export/' + (id ? id : ''), {})
            .then(({ location }) => location) // location - path to the created file.
            .catch(e => dispatch('handleError', e))
    },
}

export default {
    namespaced: false,
    state,
    actions,
    getters,
    mutations,
}
