import { PROJECT_WITH_SUBTYPES, PROJECT_SUBTYPES, PROJECT_TYPES } from '@/constants/projects'

export const getProjectTypeText = project => {
    if (!project.type) return

    let projectTypeText = Object.values(PROJECT_WITH_SUBTYPES).find(
        t => t.type === project.type && t.subtype === project.subtype
    )?.name

    if (!projectTypeText) {
        projectTypeText = project.type + ' ' + (project.subtype || '')
    }

    return projectTypeText
}

export const getPassType = project => {
    const type = project.type || ''
    const subtype = project.subtype || ''
    const is_monetary = subtype === PROJECT_SUBTYPES.MONETARY

    switch (type) {
        case PROJECT_TYPES.LOYALTY:
            if (subtype === PROJECT_SUBTYPES.STAMP) return 'Stamp Card'
            if (subtype === PROJECT_SUBTYPES.MEMBER) return 'Membership Card'
            return type
        case PROJECT_TYPES.SUBSCRIPTION:
            return 'subscription'
        case PROJECT_TYPES.COUPON:
            if (is_monetary) return PROJECT_SUBTYPES.MONETARY
            return type
        case PROJECT_TYPES.VOUCHER:
            if (is_monetary) return 'monetary'
            if (subtype === PROJECT_SUBTYPES.KAISUKEN) return 'kaisuken'
            return type
        case PROJECT_TYPES.TICKET:
            if (subtype === PROJECT_SUBTYPES.EVENT) return 'event ticket'
            if (subtype === PROJECT_SUBTYPES.ADMISSION) return 'admission ticket'
            return type
        case PROJECT_TYPES.TRANSPORT:
            if (subtype === PROJECT_SUBTYPES.COMMUTER) return 'commuter pass'
            return 'transport'
        case PROJECT_TYPES.BOOKING:
            if (subtype === PROJECT_SUBTYPES.LOUNGE) return 'Airport Lounge Pass'
            if (subtype === PROJECT_SUBTYPES.HOTEL_PASS) return 'Hotel Pass'
            if (subtype === PROJECT_SUBTYPES.MEETING_PASS) return 'matching pass'
            return type
        default:
            return type
    }
}
