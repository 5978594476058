<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import KUnlockWorkspace from '@/components/unlockWorkspace.vue'
import WorkspaceSwitcher from '@/components/workspaceSwitcher.vue'
import VenueSwitcher from '@/components/venueSwitcher.vue'

export default {
    props: ['drawer'],
    components: { KUnlockWorkspace, WorkspaceSwitcher, VenueSwitcher },
    data() {
        return {
            unlocked: null,
            showUnlock: false,
            unlocking: false,
            ws: null,
        }
    },
    computed: {
        ...mapState({
            user: state => state.account.user,
        }),
        ...mapGetters([
            'has_payment_connected',
            'has_owner_rights',
            'is_squareup_mode',
            'is_subscribed',
            'signed_in',
            'staff_venue_mode',
            'on_trial',
            'workspace_flags',
            'subscribed_mailer',
        ]),
        drawer_mini: {
            set(val) {
                this.$store.commit('SET_WORKSPACE_DRAWER_MINI', val)
            },
            get() {
                return this.$store.state.workspaces.drawer_mini
            },
        },
        view() {
            return this.$route.name
        },
        billing_caption() {
            return this.is_subscribed && !this.on_trial ? 'billing' : 'upgrade'
        },
        user_project_counts() {
            return this.user.project_counts || {}
        },
        origin_user() {
            return this.user.origin ? this.user.origin : this.user
        },
    },

    watch: {
        showUnlock(value) {
            if (!value) {
                this.unlocked = this.user.is_unlocked
            }
        },
        'user.is_unlocked': function (val) {
            this.unlocked = val
        },
    },

    mounted() {
        this.unlocked = this.user.is_unlocked
    },

    tasks(t) {
        return {
            lock: t(async function () {
                this.$store.dispatch('resetError')
                if (!this.user.is_unlocked) return
                await this.lockWorkspace()
            }).flow('drop'),
        }
    },

    methods: {
        ...mapActions(['lockWorkspace', 'switchContext']),
        toggleLock(value) {
            if (value) {
                this.showUnlock = true
            } else {
                this.lock.run()
            }
        },
        isCreated(type, types) {
            if (types && types.length) {
                for (var i = types.length - 1; i >= 0; i--) {
                    if (this.user_project_counts[types[i]]) return true
                }
            }
            return !!this.user_project_counts[type]
        },
        switchTo(workspace) {
            let workspaceId = workspace?.id || this.origin_user.id

            if (workspaceId == this.user.id) return

            this.switchContext(workspaceId).then(() => {
                window.location = '/'
            })
        },
    },
}
</script>

<template>
    <v-list class="text-capitalize pa-6" dense>
        <div>
            <workspace-switcher v-if="signed_in && !is_squareup_mode" class="mb-6" />
            <venue-switcher v-if="staff_venue_mode && signed_in" />
            <v-menu
                v-if="origin_user.acl >= 10"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x>
                <template #activator="{ on }">
                    <v-btn v-on="on" class="mb-6 w-100" color="primary">Switch to</v-btn>
                </template>
                <v-card>
                    <v-card-text>
                        <k-text-field hide-details v-model="ws" label="Switch into:" />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn @click="switchTo({ id: ws })" text>switch</v-btn>
                    </v-card-actions>
                </v-card>
            </v-menu>
        </div>

        <template v-if="!is_squareup_mode || (is_squareup_mode && is_subscribed)">
            <v-btn to="/create" color="primary" class="w-100 mb-6" x-large>
                <v-icon>add</v-icon>
                <span class="text-capitalize">{{ $t('create project') }}</span>
            </v-btn>

            <v-list-item exact to="/">
                <v-list-item-icon><k-icon>mdi-view-dashboard-outline</k-icon></v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('home') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item exact to="/projects">
                <v-list-item-icon>
                    <img src="/images/projects_list.svg" height="16" class="sidebar-icon" />
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('projects') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="is_subscribed" to="/contacts">
                <v-list-item-icon><k-icon>mdi-account-multiple-outline</k-icon></v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('CRM') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="is_subscribed" to="/venues">
                <v-list-item-icon><k-icon>mdi-map-marker-outline</k-icon></v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ $t('venues') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item to="/campaigns" v-if="subscribed_mailer">
                <v-list-item-icon><k-icon>mdi-bullhorn-outline</k-icon></v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ $t('email campaigns') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item to="/designer" v-if="$root.dev">
                <v-list-item-icon><k-icon>mdi-palette-swatch-outline</k-icon></v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('Pass Designer') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <hr class="sidebar-divider" />
        </template>

        <v-list-item to="/settings">
            <v-list-item-icon><k-icon>mdi-cog-outline</k-icon></v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ $t('settings') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <template v-if="has_owner_rights">
            <v-list-item :class="{ 'indigo lighten-5': !is_subscribed || on_trial }" to="/billing">
                <v-list-item-icon><k-icon>mdi-cash-multiple</k-icon></v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t(billing_caption) }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>

        <hr class="sidebar-divider" v-if="workspace_flags.with_encryption" />

        <v-list-item v-if="workspace_flags.with_encryption">
            <v-list-item-content class="justify-center">
                <v-switch
                    :label="$t('unlock')"
                    v-model="unlocked"
                    class="text-capitalize mt-0"
                    :loading="unlocking || lock.isActive"
                    :disabled="unlocking || lock.isActive"
                    @change="toggleLock" />
            </v-list-item-content>
            <k-unlock-workspace v-model="showUnlock" :loading.sync="unlocking" />
        </v-list-item>

        <hr class="sidebar-divider" />

        <v-list-item href="https://help.kinchaku.com/" target="_blank">
            <v-list-item-icon><k-icon>mdi-book-open-page-variant-outline</k-icon></v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title>{{ $t('manual') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item href="/docs" target="_blank">
            <v-list-item-icon><k-icon>mdi-text-box-outline</k-icon></v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title>{{ $t('API Reference') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item href="https://kinchaku.com/privacy/" target="_blank">
            <v-list-item-icon><k-icon>mdi-security</k-icon></v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title>{{ $t('privacy policy') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item href="https://kinchaku.com/terms/" target="_blank">
            <v-list-item-icon><k-icon>mdi-file-document-outline</k-icon></v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title>{{ $t('terms & conditions') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<style lang="scss" scoped>
@import 'vuetify/src/styles/styles.sass';

.select-workspace::v-deep {
    background-color: #000;

    .v-input__control {
        .v-input__slot {
            box-shadow: none !important;
        }
    }
}

.sidebar-divider {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #d7d8de;
    margin: $spacer * 4 $spacer * -6;
    padding: 0;
}
.v-list::v-deep {
    .v-list-item {
        padding: $spacer * 4;
        min-height: 24px;

        &::before {
            border-radius: $spacer;
        }

        &--active {
            &::before {
                background-color: map-get($indigo, 'base');
            }
            .v-list-item__title {
                color: var(--v-primary-base);
            }
        }

        &__icon {
            margin: 0 $spacer * 6 0 0;
        }

        &__content {
            padding: 0;
        }

        &__title {
            font-size: 16px;
            line-height: 1.2rem;
        }
    }
}

.sidebar-icon {
    opacity: 0.54;
    display: block;
    margin-top: $spacer;
}

.v-list-item--active {
    .sidebar-icon {
        opacity: 0.87;
    }
}

// .v-list__tile--active {
//     background-color: #f5f5f5;
// }
// .v-list__group__header .v-list__group__header__prepend-icon {
//     min-width: 48px;
// }
// .v-application--is-ltr .v-list-group--no-action > .v-list-group__items > .v-list-item {
//     padding-left: 16px !important;
// }
</style>
